<template>
  <!-- begin::FareStandard profile tabs -->
  <div v-if="isAuthenticated">
    <div class="manager-title">
      <div>
        {{ $t("FARES.STANDARD.TITLE_EDIT") }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <FaresStandardEdit :fare-standard-id="FareStandardID" />
  </div>
  <!-- end::FareStandard profile tabs -->
</template>

<script>
import { mapGetters } from "vuex";
import FaresStandardEdit from "@/view/pages/manager/fares/standard/Edit.vue";

export default {
  name: "FaresStandardTabs",
  components: {
    FaresStandardEdit
  },
  computed: {
    ...mapGetters(["isAuthenticated"])
  },
  data() {
    return {
      FareStandardID: 0,
      TabList: [
        {
          Position: 0,
          Name: "profile",
          Title: this.$i18n.t("FARES.COMMON.FARE")
        },
        {
          Position: 1,
          Name: "discounts",
          Title: this.$i18n.t("FARES.COMMON.DISCOUNTS")
        }
      ]
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.FareStandardID = parseInt(this.$route.params.id);
    } else {
      this.FareStandardID = -1;
    }
  }
};
</script>
